import { createSlice } from '@reduxjs/toolkit';
import ApiClientConnector from 'src/api/ApiClientConnector';

const initialState = {
    stockItems: [],
    sellingMarkList:[],
    selectedStockItemList:[],
    isManageView: false,
};

export const StockItemSlice = createSlice({
    name: 'stock-items',
    initialState,
    reducers: {
        getStockItems: (state, action) => {
            state.stockItems = action.payload.map(item => ({
                ...item,
                standardCode: item.standard?.code,
                gradeCode: item.grade?.code
            }));
        },
        getSellingMarkList: (state, action) => {
            state.sellingMarkList = action.payload;
        },
        addDocumentId:(state, action) => {
            var index = state.stockItems.findIndex(i => i.id === action.payload.stockItemID);
            state.stockItems[index].deliveryOrderDocumentId  = action.payload.documentID;
        },
        setSelectedStockItemList: (state, action) => {
            state.selectedStockItemList = action.payload;
        },
        clearData: (state) => {
            state.selectedStockItemList = [];
        },
        setManageView: (state, action) => {
            state.isManageView = action.payload;
        },
    },
});

export const { getStockItems, getSellingMarkList, addDocumentId, setSelectedStockItemList, clearData, setManageView } = StockItemSlice.actions;

export const fetchStockItems = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.stockItems.get(criteria);
        dispatch(getStockItems(response));
        return(response);
    }
    catch (error){
        throw error;
    }
}

export const uploadStockItems = (instance, data) => async () => {
    try {
        const client = await ApiClientConnector(instance);
        await client.stockItems.upload(data);
    } catch (error) {
        throw error;
    }
}

export const uploadDeliveryOrderDocument = (instance, id, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.stockItems.uploadDeliveryOrderDocument(id, data);
        dispatch(addDocumentId({documentID: response.documentId, stockItemID:id}));
    } catch (error) {
        throw error;
    }
}

export const getDeliveryOrderDocument = (instance, id) => async () => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.stockItems.getDeliveryOrderDocument(id);
        return await response.blob();
    } catch (error) {
        throw error;
    }
}

export const fetchSellingMarks = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.stockItems.get(criteria);
        dispatch(getSellingMarkList(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const fetchStockItemsForCollection = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.stockItems.getStockItemsForCollection(criteria);
        dispatch(getStockItems(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default StockItemSlice.reducer;