class HttpClient {
  constructor(options = {}) {
    this._baseURL = options.baseURL || "";
    this._headers = options.headers || {};
    this._headers["content-type"] = "application/json";
    this._headers.Authorization = `Bearer ${options.token}`;
  }

  setHeader(key, value) {
    this._headers[key] = value;
    return this;
  }

  removeHeader(key) {
    delete this._headers[key];
  }

  async handleErrorResponse(res) {
    const errorData = await res.json();
    const errorMessage = errorData.Message ? errorData.Message : Object.values(errorData.errors).flat().join(', ');
    console.error("Error:", errorMessage);
    const error = new Error(errorMessage);
    error.response = res;
    error.data = errorData;
    throw error;
  }

  async _fetchJSON(endpoint, options = {}) {
    const res = await fetch(this._baseURL + endpoint, {
      ...options,
      headers: this._headers,
    });

    if (!res.ok) {
      await this.handleErrorResponse(res);
    }

    if (options.parseResponse !== false && res.status !== 204)
      return res.json();
    return undefined;
  }

  async _fetchFile(endpoint, options = {}) {
    const res = await fetch(this._baseURL + endpoint, {
      ...options,
      headers: this._headers,
    });

    if (!res.ok) {
      await this.handleErrorResponse(res);
    }

    if (options.parseResponse !== false && res.status !== 204)
      return res;
    return undefined;
  }

  getFile(endpoint, options = {}) {
    return this._fetchFile(endpoint, {
      ...options,
      method: "GET",
    });
  }

  get(endpoint, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      method: "GET",
    });
  }

  post(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      body: JSON.stringify(body),
      method: "POST",
    });
  }

  put(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      body: JSON.stringify(body),
      method: "PUT",
    });
  }

  delete(endpoint, options = {}) {
    return this._fetchJSON(endpoint, {
      parseResponse: true,
      ...options,
      method: "DELETE",
    });
  }

  upload(method, endpoint, body, options = {}) {
    this.removeHeader("content-type");
    return this._fetchJSON(endpoint, {
      ...options,
      body: body,
      method: method,
    });
  }
}
export default HttpClient;