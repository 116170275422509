import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import AuditEventsSlice from './audit-events/AuditEventsSlice';
import CustomerManageSlice from './customer/CustomerManageSlice';
import ManageStandardsSlice from './standards/ManageStandardsSlice';
import OrderSlice from './order/OrderSlice';
import StockItemSlice from './stock-item/StockItemSlice';
import UsersSlice from './users/UsersSlice';
import TypeSlice from './types/TypesSlice';
import CompanyProfileSlice from './company-profile/CompanyProfileSlice';
import CompanyContactSlice from './company-profile/CompanyContactSlice';
import CustomerBankAccountSlice from './customer/CustomerBankAccountSlice';
import CompanyAddressSlice from './company-profile/CompanyAddressSlice'
import CountrySlice from './country/CountrySlice';
import CompanyBankAccountSlice from './company-profile/CompanyBankAccountSlice';
import CustomerAddressSlice  from './customer/CustomerAddressSlice';
import CustomerContactSlice  from './customer/CustomerContactSlice';
import ProformaInvoiceSlice  from './order/ProformaInvoiceSlice';
import CompanySettingsSlice from './company-profile/CompanySettingsSlice';
import BlendSheetSlice from './blend-sheets/BlendSheetSlice';
import GradeSlice from './grade/GradeSlice';
import FacilitySlice from './facility/FacilitySlice';
import WarehouseLocationSlice from './warehouse-location/WarehouseLocationSlice';
import CollectionNoteSlice from './collections/CollectionNoteSlice';
import FacilityLocationSlice from './facility/FacilityLocationSlice';
import FacilityZoneSlice from './facility/FacilityZoneSlice';
import StockItemUnitsSlice from './stock-item/StockItemUnitsSlice';
import ProductCertificationsSlice from './products/ProductCertificationsSlice';
import ProductCountryOriginsSlice from './products/ProductCountryOriginsSlice';
import ProductsSlice from './products/ProductsSlice';
import SupplierSlice from './supplier/SupplierSlice';
import ItemCatalogueSlice from './item-catalogue/ItemCatalogueSlice';
import ItemCategorySlice from './item-catalogue/ItemCategorySlice';
import BomSlice from './bom/BomSlice';
import SupplierItemSlice from './item-catalogue/SupplierItemSlice';
import BuyingPlanSlice from './buying-plan/BuyingPlanSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    auditEventsReducer: AuditEventsSlice,
    customer: CustomerManageSlice,
    standardReducer: ManageStandardsSlice,
    orderReducer: OrderSlice,
    stockItemReducer: StockItemSlice,
    usersReducer: UsersSlice,
    companyProfileReducer: CompanyProfileSlice,
    companyAddressReducer: CompanyAddressSlice,
    companyBankAccountReducer: CompanyBankAccountSlice,
    typeReducer: TypeSlice,
    companyContactReducer: CompanyContactSlice,
    customerBankAccountReducer: CustomerBankAccountSlice,
    countryReducer: CountrySlice,
    customerAddressReducer: CustomerAddressSlice,
    customerContactReducer: CustomerContactSlice,
    proformaInvoiceReducer: ProformaInvoiceSlice,
    companySettingsReducer: CompanySettingsSlice,
    blendSheetReducer: BlendSheetSlice,
    gradeReducer: GradeSlice,
    facilityReducer :FacilitySlice,
    warehouseLocationReducer:WarehouseLocationSlice,
    collectionNoteReducer: CollectionNoteSlice,
    facilityLocationReducer: FacilityLocationSlice,
    facilityZoneReducer: FacilityZoneSlice,
    stockItemUnitReducer: StockItemUnitsSlice,
    productCertificationsReducer: ProductCertificationsSlice,
    productCountryOriginsReducer: ProductCountryOriginsSlice,
    productsReducer: ProductsSlice,
    supplierReducer: SupplierSlice,
    itemCatalogueReducer: ItemCatalogueSlice,
    itemCategoryReducer: ItemCategorySlice,
    bomReducer: BomSlice,
    supplierItemReducer: SupplierItemSlice,
    buyingPlanReducer: BuyingPlanSlice
  },
});

export default store;
