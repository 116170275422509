import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    standardDataList: [],
    stockItemBalanceList: [],
    selectedOrderList: [],
    isManageView: false,
    summaryStandardData: [],
    summaryBuyingStandards: []
};

export const BuyingPlanSlice = createSlice({
    name: 'buying-plan',
    initialState,
    reducers: {
        setRequiredData: (state, action) => {
            if (action.payload.isAdd)
            {
                state.standardDataList.push(...action.payload.standardDataList);
                state.stockItemBalanceList.push(...action.payload.stockItemBalanceList);
            }
            else {
                state.standardDataList = action.payload.standardDataList;
                state.stockItemBalanceList = action.payload.stockItemBalanceList;
            }
        },
        setSelectedOrderList: (state, action) => {
            state.selectedOrderList = action.payload;
        },
        setManageView: (state, action) => {
            state.isManageView = action.payload;
        },
        clearData: (state) => {
            state.standardDataList = [];
            state.stockItemBalanceList = [];
            state.selectedOrderList = [];
            state.summaryStandardData = [];
            state.summaryBuyingStandards = [];
        },
        setSummaryStandardData: (state, action) => {
            state.summaryStandardData = action.payload.standardData;
            state.summaryBuyingStandards = action.payload.buyingStandards; 
        },
        setEstimatedPrice: (state, action) => { 
            const index = state.summaryStandardData.findIndex(i => i.id === action.payload.id);
            if (index !== -1) {
                const updatedSummaryData = state.summaryStandardData[index];
                state.summaryStandardData[index] = {...updatedSummaryData, estimatedPrice: action.payload.estimatedPrice, estimatedCost: action.payload.estimatedPrice * updatedSummaryData.purchaseQuantity };
            }
        },
        setPurchaseQuantity: (state, action) => { 
            const index = state.summaryStandardData.findIndex(i => i.id === action.payload.id);
            if (index !== -1) {
                const updatedSummaryData = state.summaryStandardData[index];
                state.summaryStandardData[index] = {...updatedSummaryData, purchaseQuantity: action.payload.purchaseQuantity, estimatedCost: updatedSummaryData.estimatedPrice * action.payload.purchaseQuantity };
            }
        }
    }
})

export const { setRequiredData, setSelectedOrderList, setManageView, clearData, setSummaryStandardData, setEstimatedPrice, setPurchaseQuantity } = BuyingPlanSlice.actions;

export const fetchBuyingPlans = (instance, data, isAdd = false) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.buyingPlans.post(data);
        dispatch(setRequiredData({...response, isAdd}));
        return response;
    } catch (error) {
        throw error;
    }
}

export default BuyingPlanSlice.reducer;