import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    blendSheetList: [],
    selectedBlendSheet: null,
    selectedOrderDetail: null
};

export const BlendSheetSlice = createSlice({
    name: 'blend-sheets',
    initialState,
    reducers: {
        getBlendSheets: (state, action) => {
            state.blendSheetList = action.payload;
        },
        setSelectedBlendSheet: (state, action) => {
            state.selectedBlendSheet = action.payload;
        },
        setSelectedOrderDetail: (state, action) => {
            state.selectedOrderDetail = action.payload;
        },
        clearAllState: (state) => {
            state = initialState;
        }
    }
})

export const { getBlendSheets, setSelectedBlendSheet, setSelectedOrderDetail, clearAllState } = BlendSheetSlice.actions;

export const fetchBlendSheets = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.blendSheets.get(criteria);
        dispatch(getBlendSheets(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const fetchBlendSheet = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.blendSheets.getById(id);
        dispatch(setSelectedBlendSheet(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const addBlendSheet = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.blendSheets.post(data);
        dispatch(setSelectedBlendSheet(response));
        return(response);
    }
    catch (error){
        throw error;
    }
}

export const updateBlendSheet = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.blendSheets.updateBlendStandards(data.id, data);
        dispatch(setSelectedBlendSheet(response));
        return(response);
    }
    catch (error){
        throw error;
    }
}

export const updateLotAllocation = (instance, id, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.blendSheets.updateAllocations(id, data);
        return(response);
    }
    catch (error){
        throw error;
    }
}

export default BlendSheetSlice.reducer;