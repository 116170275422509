import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    selectedSupplier: null,
    supplierItemList: []
};

export const SupplierItemSlice = createSlice({
    name: 'supplier-item',
    initialState,
    reducers: {
        addOrUpdateSupplierItem: (state, action) => {
            const index = state.supplierItemList.findIndex(i => i.id === action.payload.id);
            if (index >= 0) {
                state.supplierItemList[index] = action.payload;
            } else {
                state.supplierItemList.push(action.payload);
            }
        },
        setSupplierItems: (state, action) => {
            state.supplierItemList = action.payload;
        },
        setSelectedSupplier: (state, action) => {
            state.selectedSupplier = action.payload;
        }
    }
});

export const { addOrUpdateSupplierItem, setSupplierItems, setSelectedSupplier } = SupplierItemSlice.actions;

export const fetchSupplierItems = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.supplierItems.get(criteria);
        dispatch(setSupplierItems(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchSupplierItem = (instance, id) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.supplierItems.get(id);
        dispatch(setSelectedSupplier(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const saveSupplierItem = (instance, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = data.id ? await client.supplierItems.put(data.id, data) : await client.supplierItems.post(data);
        dispatch(addOrUpdateSupplierItem(response));
        dispatch(setSelectedSupplier(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export default SupplierItemSlice.reducer;